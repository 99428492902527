import React from 'react'
import {useTranslation} from 'react-i18next'
import {NexusGenEnums, NexusGenRootTypes} from 'kubik-server'

import Box from '@material-ui/core/Box'
import {styled, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {Center, Divider} from '../../../../../components/CommonBox'
import {useSettlementRequestStatuEnum} from '../../../../../hooks/useEnumList'

import ConfirmedIcon from '../../../../../components/Navigation/components/RebateState/Icon/Schvaleno'
import AppliedIcon from '../../../../../components/Navigation/components/RebateState/Icon/Odeslano'
import EmptyIcon from '../../../../../components/Navigation/components/RebateState/Icon/Prazdne'
import InProgressIcon from '../../../../../components/Navigation/components/RebateState/Icon/Rozpracovano'
import DeniedIcon from '../../../../../components/Navigation/components/RebateState/Icon/Zamitnuto'

const Label = styled(Typography)({
  marginBottom: 6,
})

const Status = styled(Typography)<Theme, { status: IProps['status'] }>(({ status, theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    color: status === 'CONFIRMED'
    ? theme.palette.primary.main
    : status === 'APPLY'
    ? theme.palette.common.black
    : status === 'DENIED'
    ? theme.palette.error.main
    : status === 'UNFINISHED'
    ? 'orange'
    : '#000',
    "& > svg": {
      width: "0.75em"
    }
  }
})

export const calcSummaryStatus = (
  status: NexusGenEnums['SettlementRequestStatus'],
  overview?: NexusGenRootTypes['AnnualRebateOverview'] | null,
): NexusGenEnums['SettlementRequestStatus'] | 'UNFINISHED' => {
  if (status === 'APPLY') {
    return 'APPLY'
  }

  if (overview) {
    const hasDenied = Object.values(overview).some(rebate => {
      if (!rebate) {
        return false
      }

      return rebate.status === 'DENIED'
    })

    if (hasDenied) {
      return 'DENIED'
    }

    return 'CONFIRMED'
  }

  return 'UNFINISHED'
}

interface IProps {
  status: NexusGenEnums['SettlementRequestStatus'] | 'UNFINISHED'
  className?: string
}

const StatusIcon: React.FC<{ status: IProps['status'] }> = ({ status }) => {
  switch (status) {
    case "APPLY":
      return <AppliedIcon />
    case "CONFIRMED":
      return <ConfirmedIcon />
    case "DENIED":
      return <DeniedIcon fill="currentColor" />
    case "UNFINISHED":
      return <InProgressIcon fill="currentColor" />
    case "NEW":
      return <EmptyIcon />
    case "CANCELED":
      return <EmptyIcon />
    default:
      return null
  }
}

const Summary: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { translate } = useSettlementRequestStatuEnum()
  const { status, className } = props

  return (
    <Box width="100%" className={className}>
      <Divider />
      <Center>
        <Box py={3} px={4} width="100%">
          <Label variant="h4" align="center">
            {t('taxRebates.rebateStatus')}
          </Label>
          <Status
            variant="h2"
            status={status}
            align="center"
          >
            {translate(props.status)}
            <StatusIcon status={props.status} />
          </Status>
        </Box>

        {/* <Box py={3} pl={4} width="45%">
          <Label variant="h4">{t('taxRebates.totalAmount')}</Label>
          <Typography variant="h2">
            <Currency value={props.amount} postfix="Kč" />
          </Typography>
        </Box> */}
      </Center>
      <Divider />
    </Box>
  )
}

export default Summary
