import {Bold} from '../../../../../components/CommonBox'
import {useMonthShortcutEnum} from '../../../../../hooks/useEnumList'
import {NexusGenFieldTypes} from 'kubik-server'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {useTheme} from '@material-ui/core/styles'

const SpouseRebateOverview: React.FC<{
  data: NexusGenFieldTypes['SpouseRebate']
}> = ({ data }) => {
  const { t } = useTranslation()
  const { translate } = useMonthShortcutEnum()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('common.firstname')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>{data.firstname}</Typography>
      </Grid>

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('common.lastname')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>{data.lastname}</Typography>
      </Grid>

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('common.nationalIDNumber')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>{data.nationalIDNumber}</Typography>
      </Grid>

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('common.monthOfApply2')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>
          {data.applyInMonths &&
            data.applyInMonths.map((month) => translate(month)).join(', ')}
        </Typography>
      </Grid>

      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('common.ztpp')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>{data.ztpp ? t('common.yes') : t('common.no')}</Typography>
      </Grid>

      {data.ztpp && (
        <>
          <Grid item xs={compactScreen ? 12 : 5}>
            <Bold>{t('common.monthOfApply2', { context: 'ZTPP' })}</Bold>
          </Grid>
          <Grid item xs={compactScreen ? 12 : 7}>
            <Typography>
              {data.ztppMonths &&
                data.ztppMonths.map((month) => translate(month)).join(', ')}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Bold mb={1} display="block">
          {t('common.uploadedConfirmations')}
        </Bold>
      </Grid>
      <Box pl={compactScreen ? 0 : 4}>
        {data.marriageCertificateFiles &&
          data.marriageCertificateFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
        {/* {data.spousesIncomeConfirmationFiles &&
          data.spousesIncomeConfirmationFiles.map(file => (
            <FileLink key={file.id} file={file} />
          ))} */}
        {data.ztppConfirmationFiles &&
          data.ztppConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
        {data.socialConfirmationFiles &&
          data.socialConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
      </Box>
    </Grid>
  )
}

export default SpouseRebateOverview
