import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'

export type TUser = NexusGenFieldTypes['User']
export type TUnit = NexusGenFieldTypes['Unit']

export interface ICustomerUpdateData {
  customerUpdate: NexusGenFieldTypes['Mutation']['customerUpdate']
}
export type ICustomerUpdateVariables = NexusGenArgTypes['Mutation']['customerUpdate']
export const CUSTOMER_UPDATE = gql`
  mutation customerUpdate($data: CustomerUpdateInput!) {
    customerUpdate(data: $data) {
      id
      activated
      name
      groupName
      ico
      dic
      address
      activationAt
      activationAnnualtax
      activationFormStep
      activationKeeperIsEmployee
      divideByUnits
      sharedMessages
      allowFilesUpload
      dontRequireBasicRebateAffidavitFiles
      allowDocumentsForViewer
      createdBy {
        id
        email
        role
      }
    }
  }
`

export interface IFinishCustomerRegistrationData {
  finishCustomerRegistration: NexusGenFieldTypes['Mutation']['finishCustomerRegistration']
}
export const FINISH_CUSTOMER_REGISTRATION = gql`
  mutation finishCustomerRegistration(
    $where: ImportLogCustomerWhereInput
    $data: CustomerUpdateInput
  ) {
    finishCustomerRegistration(where: $where, data: $data) {
      id
      activated
    }
  }
`

export interface ICustomerData {
  customer: NexusGenFieldTypes['Customer'] & {
    createdBy: NexusGenFieldTypes['User']
  }
  units: TUnit[]
  roles: NexusGenFieldTypes['User'][]
  employees: NexusGenFieldTypes['User'][]
}
export const CUSTOMER_UNITS_ROLES_EMPLOYEES = gql`
  query customer {
    customer {
      id
      activated
      address
      dic
      ico
      name
      groupName
      activationAt
      activationFormStep
      divideByUnits
      activationAnnualtax
      activationKeeperIsEmployee
      allowFilesUpload
      allowDocumentsForViewer
      dontRequireBasicRebateAffidavitFiles
      createdBy {
        id
        email
        role
      }
    }
    units {
      id
      name
      color
    }
    roles: users(where: { role_in: [ACCOUNTANT, VIEWER, IMPLEMENTATOR] }) {
      id
      firstname
      lastname
      nickname
      email
      role
      language
      blockedAt
      blocked
      managedUnits {
        id
        name
        color
      }
    }
    employees: users(where: { role: EMPLOYEE }) {
      id
      firstname
      lastname
      email
      role
      dateOfEmployment
      dateOfQuit
      personalNumber
      signedConfirmation
      employmentType
      language
      unit {
        id
        name
      }
      importLog {
        id
      }
    }
  }
`

export interface IUnitsData {
  units: TUnit[]
}
export const UNITS = gql`
  query units {
    units {
      id
      name
      color
    }
  }
`

export interface IUnitCreateData {
  createUnit: TUnit
}
export type TCreateUnitVariables = NexusGenArgTypes['Mutation']['createUnit']
export const CREATE_UNIT = gql`
  mutation createUnit($data: UnitCreateInput!) {
    createUnit(data: $data) {
      id
      name
      color
    }
  }
`

export interface IUnitUpdateData {
  updateUnit: TUnit
}
export type TUpdateUnitVariables = NexusGenArgTypes['Mutation']['updateUnit']
export const UNIT_UPDATE = gql`
  mutation updateUnit($data: UnitUpdateInput!, $where: UnitWhereUniqueInput!) {
    updateUnit(where: $where, data: $data) {
      id
      name
      color
    }
  }
`

export interface IUnitDeleteData {
  deleteUnit: TUnit
}
export const UNIT_DELETE = gql`
  mutation deleteUnit($where: UnitWhereUniqueInput!) {
    deleteUnit(where: $where) {
      id
    }
  }
`

export interface IRoleData {
  roles: NexusGenFieldTypes['User'][]
}

export const ROLES = gql`
  query roles {
    roles: users(where: { role_in: [ACCOUNTANT, VIEWER, IMPLEMENTATOR] }) {
      id
      firstname
      lastname
      nickname
      email
      role
      language
      managedUnits {
        id
        name
        color
      }
    }
  }
`

export interface IUserUpdateData {
  updateUser: TUser
}
export type TUpdateUserVariables = NexusGenArgTypes['Mutation']['updateUser']
export const USER_UPDATE = gql`
  mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      firstname
      lastname
      nickname
      email
      employmentType
      role
      dateOfEmployment
      dateOfQuit
      personalNumber
      signedConfirmation
      fullname
      language
      unit {
        id
        name
      }
      managedUnits {
        id
        name
        color
      }
      importLog {
        id
      }
    }
  }
`

export interface IUserDeleteData {
  deleteUser: Pick<TUser, 'id' | 'firstname' | 'lastname'>
}
export const USER_DELETE = gql`
  mutation deleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
      firstname
      lastname
    }
  }
`

export interface ICreateUserData {
  createUser: NexusGenFieldTypes['User']
}
export type TCreateUserVariables = NexusGenArgTypes['Mutation']['createUser']
export const CREATE_USER = gql`
  mutation createUser($data: UserCreateInput!, $doNotInvite: Boolean) {
    createUser(data: $data, doNotInvite: $doNotInvite) {
      id
      firstname
      lastname
      nickname
      email
      employmentType
      role
      dateOfEmployment
      dateOfQuit
      personalNumber
      signedConfirmation
      language
      unit {
        id
        name
      }
      managedUnits {
        id
        name
      }
      importLog {
        id
      }
    }
  }
`

export interface IEmployeesWithData {
  users: NexusGenFieldTypes['User'][]
  units: NexusGenFieldTypes['Unit'][]
  customer: {
    divideByUnits: boolean
    activationKeeperIsEmployee: boolean
  }
}

export const EMPLOYEES_UNITS = gql`
  query employeesWithUnits {
    users(where: { role: EMPLOYEE }) {
      id
      firstname
      lastname
      nickname
      email
      employmentType
      role
      language
      unit {
        id
        name
        color
      }
    }
  }
`

export interface IChangeKeeperData {
  changeKeeper: TUser
}
export type TChangeKeeperVariables = NexusGenArgTypes['Mutation']['changeKeeper']
export const CHANGE_KEEPER = gql`
  mutation changeKeeper($data: KeeperChangeInput!) {
    changeKeeper(data: $data)
  }
`

export interface TUserBlockData {
  blockUser: TUser
}
export type TUserBlockVariables = NexusGenArgTypes['Mutation']['blockUser']
export const USER_BLOCK = gql`
  mutation blockUser($where: UserWhereUniqueInput!, $unblock: Boolean) {
    blockUser(where: $where, unblock: $unblock) {
      id
      blocked
      blockedAt
    }
  }
`

// use generated
export const CREATE_CUSTOMER = gql`
  mutation createNewCustomer($data: CustomerCreateInput!) {
    createNewCustomer(data: $data) {
      id
      name
      groupName
      ico
      dic
      activationFormStep
      address
      activationAt
      activationAnnualtax
      activationKeeperIsEmployee
      divideByUnits
      sharedMessages
      allowFilesUpload
      allowDocumentsForViewer
      dontRequireBasicRebateAffidavitFiles
      activated
      annualRebatesPeriodStart
      annualRebatesPeriodEnd
      annualRebatesPeriodExtraEnd
      createdBy {
        id
        email
        role
      }
    }
  }
`

export const NEW_CUSTOMER_UNITS = gql`
  query superadminUnits($where: SuparadminUnitWhereInput!) {
    superadminUnits(where: $where) {
      id
      name
      color
    }
  }
`

export const NEW_CUSTOMER_CREATE_UNIT = gql`
  mutation superadminCreateUnit($data: SuparadminUnitCreateInput!) {
    superadminCreateUnit(data: $data) {
      id
      name
      color
    }
  }
`

export const CUSTOMER_UPDATE_SUPERADMIN = gql`
  mutation customerUpdateSuperadmin(
    $where: CustomerWhereUniqueInput!
    $data: CustomerUpdateInput!
  ) {
    customerUpdateSuperadmin(where: $where, data: $data) {
      id
      activated
      name
      groupName
      ico
      dic
      address
      activationAt
      activationAnnualtax
      activationFormStep
      activationKeeperIsEmployee
      divideByUnits
      sharedMessages
      allowFilesUpload
      allowDocumentsForViewer
      dontRequireBasicRebateAffidavitFiles
      annualRebatesPeriodStart
      annualRebatesPeriodEnd
      annualRebatesPeriodExtraEnd
      createdBy {
        id
        email
        role
      }
    }
  }
`

export const NEW_CUSTOMER_CREATE_USER = gql`
  mutation superadminCreateUser($data: SuperadminUserCreateInput!) {
    superadminCreateUser(data: $data) {
      id
      firstname
      lastname
      nickname
      blockedAt
      email
      employmentType
      role
      dateOfEmployment
      dateOfQuit
      personalNumber
      signedConfirmation
      entryFormDoneAt
      language
      unit {
        id
        name
        color
      }
      managedUnits {
        id
        name
        color
      }
      importLog {
        id
      }
    }
  }
`

export const NEW_CUSTOMER_ROLES = gql`
  query superadminUsers($where: SuperadminUserWhereInput!) {
    superadminUsers(where: $where) {
      id
      blockedAt
      firstname
      lastname
      nickname
      email
      role
      language
      invitedAt
      managedUnits {
        id
        name
        color
      }
    }
  }
`

export const NEW_CUSTOMER_EMPLOYEES = gql`
  query superadminEmployees($where: SuperadminUserWhereInput!) {
    superadminUsers(where: $where) {
      id
      firstname
      lastname
      email
      role
      dateOfEmployment
      dateOfQuit
      personalNumber
      signedConfirmation
      employmentType
      language
      entryFormDoneAt
      unit {
        id
        name
      }
      importLog {
        id
      }
    }
  }
`

export const GET_USER_UNIQUENESS = gql`
  query userUniqueness($data: UserUniquenessInput!) {
    userUniqueness(data: $data) {
      emailInUse
      personalNumberInUse
    }
  }
`
