import {DOCUMENTS, TDocumentsData, TDocumentsVariables,} from '../Documents/gql'
import {
  ISettlementRequestLastData,
  ISettlementRequestLastVariables,
  SETTLEMENT_REQUESTS_LAST,
  TSettlementRequestLast,
} from './gql'
import {ISettlementRequestsData, SETTLEMENT_REQUESTS, TSettlementRequestsVariables,} from '../SettlementRequests/gql'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {MESSAGES, TMessagesData, TMessagesVariables} from '../Messages/gql'
import {Trans, useTranslation} from 'react-i18next'
import {useQuery} from 'react-apollo'
import Box from '@material-ui/core/Box'
import Divider from '../../../../components/Divider'
import FooterIlustration from './FooterIlustration'
import get from 'lodash/get'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import OverviewItem from './Item'
import OverviewNotice from './Notice'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {TAnnualRebateName} from "kubik-server";
import {TMonthlyRebateName} from "../../../../fragments/utils";
import {useRoleEnum} from './../../../../hooks'

interface TStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, TStylesProps>((theme: Theme) => ({
  container: {
    borderRadius: '5px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    '& hr': {
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    minHeight: `calc(100vh - ${theme.spacing(16)}px)`,
    [theme.breakpoints.up('lg')]: {
      maxHeight: `calc(100vh - ${theme.spacing(16)}px)`,
    },
  },
  content: {
    width: '100%',
    padding: theme.spacing(3, 24),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc(100vh - 256px)',
    },
  },
  date: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontFamily: 'sans-serif',
    fontSize: '0.8rem',
    justifyContent: 'flex-end',
    '& div:first-child': {
      color: '#8A8A8A',
    },
    '& div span:first-child': {
      color: 'red',
    },
    '& div h1': {
      color: '#000000',
      width: '100%',
    },
    '& div span:nth-child(2)': {
      color: '#000000',
      fontSize: '2rem',
      fontWeight: 700,
    },
    '& div span:last-child': {
      color: '#00BF43',
      fontSize: '2rem',
      textTransform: 'capitalize',
    },
    '& div:last-child': {
      color: '#00A2E3',
    },
  },
  dateRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  deniedList: {
    color: '#C51313',
    marginTop: theme.spacing(1),
  },
}))

const Overview: React.FC = () => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const { translate: translateRole } = useRoleEnum()

  const { t } = useTranslation()
  const { user } = useUser()
  const [unreadDate] = React.useState(
    moment()
      .subtract(30, 'days')
      .toISOString(),
  )

  const getYear = (type: TSettlementRequestLast['type']) => {
    switch (type) {
      case 'ANNUAL':
      case 'ANNUAL_POZP':
      case 'ANNUAL_CHANGE':
        return user.data.customer.yearOfAnnualRebates
      case 'MONTHLY':
      case 'MONTHLY_CHANGE':
      case 'MONTHLY_DONT_CREATE':
        return user.data.customer.yearOfMonthlyRebates
    }
  }

  const { data: messagesData } = useQuery<TMessagesData, TMessagesVariables>(
    MESSAGES,
    {
      fetchPolicy: 'network-only',
      variables: {
        type: 'RECEIVED',
        where: { createdAt_gte: unreadDate },
        orderBy: 'createdAt_ASC',
      },
      skip: !user.allowMessages(),
    },
  )
  const messagesCount =
    (messagesData && messagesData.messages && messagesData.messages.total) || 0

  const { data: accountantSettlementRequestsData } = useQuery<
    ISettlementRequestsData,
    TSettlementRequestsVariables
  >(SETTLEMENT_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        status: 'APPLY',
      },
    },
    skip: user.isEmployee(),
  })
  const requestsCount =
    (accountantSettlementRequestsData &&
      accountantSettlementRequestsData.settlementRequests.total) ||
    0

  const { data: documentsData } = useQuery<TDocumentsData, TDocumentsVariables>(
    DOCUMENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          createdAt_gte: unreadDate,
        },
      },
      skip: user.isViewer() && !user.allowDocumentsForViewer(),
    },
  )

  const { data: settlementRequestsData } = useQuery<
    ISettlementRequestLastData,
    ISettlementRequestLastVariables
  >(SETTLEMENT_REQUESTS_LAST, {
    variables: {
      annualYear: getYear('ANNUAL') as number,
      monthlyYear: getYear('MONTHLY') as number,
    },
    fetchPolicy: 'network-only',
    skip: !user.isEmployee(),
  })
  const annualRequest =
    (settlementRequestsData &&
      settlementRequestsData.annual &&
      settlementRequestsData.annual.items) ||
    []
  const monthlyRequest =
    (settlementRequestsData &&
      settlementRequestsData.monthly &&
      settlementRequestsData.monthly.items) ||
    []
  const settlementRequests = monthlyRequest.concat(annualRequest)

  const greeting = () => {
    const hour = moment().hour()
    if (hour >= 5 && hour < 10) return 'greeting.morning'
    else if (hour >= 10 && hour < 12) return 'greeting.forenoon'
    else if (hour >= 12 && hour < 18) return 'greeting.afternoon'
    else return 'greeting.evening'
  }

  const showNoticeAnnual =
    user.isEmployee() &&
    (user.data.customer.annualRebatesPeriodStartNotice ||
      user.data.customer.annualRebatesPeriodEndNotice)

  const showNoticeMonthly =
    user.isEmployee() &&
    !user.data.customer.isAnnualPeriod &&
    !user.data.customer.annualRebatesPeriodStartNotice &&
    moment().month() !==
      moment(user.data.customer.annualRebatesPeriodEnd).month()

  const showNoticeForeigner =
    user.isEmployee() &&
    user.data.nationality !== 'CZ' &&
    (!user.data.residenceAuthorisationFiles || !user.data.taxDomicilFiles)

  const showNotice =
    showNoticeAnnual || showNoticeMonthly || showNoticeForeigner

  const parseSettlementRequest = (
    request:
      | ISettlementRequestLastData['annual']['items'][number]
      | ISettlementRequestLastData['monthly']['items'][number],
  ) => {
    const PREVIOUS_EMPLOYER = 'previousEmployer'
    const denied: string[] = []
    const isDenied = (...names: string[]) => {
      names.forEach((name) => {
        const status = get(request, `${name}.status`)
        if (status === 'DENIED') denied.push(name)
      })
    }
    if (request.childrenRebate) {
      if (
        request.childrenRebate.children.some((ch) => ch.status === 'DENIED') ||
        request.childrenRebate.otherParentStatus === 'DENIED'
      ) {
        denied.push('childrenRebate')
      }
    }
    isDenied(
      'basicRebate',
      // 'childrenRebate',
      'studentRebate',
      'disabilityRebate',
      'ztppRebate',
      'monthlyTaxQuestionnaire',
    )

    if (request.type.indexOf('ANNUAL') === 0) {
      const {
        prevEmployers,
      } = request as ISettlementRequestLastData['annual']['items'][number]

      isDenied(
        'lifeInsuranceRebate',
        'pensionInsuranceRebate',
        'examRebate',
        'giftRebate',
        'preschoolRebate',
        'spouseRebate',
        'loanRebate',
        'taxQuestionnaire',
      )

      if (prevEmployers.some((e) => e.status === 'DENIED'))
        denied.push(PREVIOUS_EMPLOYER)
    }

    const year = getYear(request.type)
    let type = request.type.toLowerCase()
    let context: string | undefined = request.status
    let isMonthly = false
    switch (request.type) {
      case 'ANNUAL_POZP':
        const createByActualEmployer = get(
          request,
          'annualRebate.questionnaire.createByActualEmployer',
        )
        context = createByActualEmployer ? 'CANNOTCREATE' : undefined
        break
      case 'MONTHLY':
      case 'MONTHLY_CHANGE':
      case 'MONTHLY_DONT_CREATE':
        isMonthly = true
      case 'ANNUAL':
      case 'ANNUAL_CHANGE':
      default:
        type = type.split('_')[0]
        break
    }

    if (denied.length > 0 && context !== 'DENIED' && context === request.status)
      context = 'DENIED'

    const link = () => {
      const url = `/${user.data.id}`
      if (context === 'DENIED') {
        if (isMonthly) {
          if (
            request.type === 'MONTHLY_DONT_CREATE' ||
            denied.includes('monthlyTaxQuestionnaire')
          ) {
            return url + '/monthly-rebates/questionnaire'
          }
          return url + '/monthly-rebates/root'
        } else if (
          request.type === 'ANNUAL_POZP' ||
          denied.includes('taxQuestionnaire')
        ) {
          return url + '/annual-rebates/questionnaire'
        } else if (denied.includes(PREVIOUS_EMPLOYER)) {
          return url + '/annual-rebates/previousEmployers'
        } else {
          return url + '/annual-rebates/root'
        }
      } else {
        return {
          pathname: `${url}/rebates${
            !isMonthly && !user.data.allowAnnual ? '/previous' : ''
          }`,
          ...(isMonthly && { state: { monthly: true } }),
        }
      }
    }

    return {
      title: `${t(`common.${type}Rebates`, { context })} ${year}`,
      text: t(`overview.${type}Request`, { context, year }),
      link: link(),
      type: request.type,
      denied,
    }
  }

  const renderDeniedItem = (request: any, rebateName: TAnnualRebateName & TMonthlyRebateName, index: number) => {
    const reason: string | null = (request && request[rebateName] ? request[rebateName].commentOfDenied : null)
    return (
      <ListItem key={rebateName + index} style={{padding: '0 1rem'}} >
        <ListItemText>
          <strong style={{display: 'block'}}>{t(`rebate.${rebateName}`)}</strong>
          {!!reason && !!reason.length && <p style={{display: 'flex', margin: 0, whiteSpace: 'pre-line'}}>
            <span style={{marginRight: '1rem'}}>Důvod:</span> <span>{reason}</span>
          </p>}
        </ListItemText>
      </ListItem>
    )
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <Box
        width={1134}
        maxWidth="100%"
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.container}
        position="relative"
      >
        <Box
          className={classes.content}
          display="flex"
          flexDirection="row"
          maxWidth="100%"
        >
          {isNotSmallScreen ? (
            <Box className={classes.date}>
              <Box className={classes.dateRow}>{t('common.todayIs')}</Box>
              <Box className={classes.dateRow}>
                <Typography variant="h1">{t(greeting())}!</Typography>

                <Box component="span">{moment().format('D')}.&nbsp;</Box>
                <Box component="span">{moment().format('MMMM')}</Box>
              </Box>
              {/* <Box className={classes.dateRow}>Svátek má ???</Box> */}
            </Box>
          ) : (
            <Box className={classes.dateRow}>
              <Typography variant="h1">{t(greeting())}!</Typography>
            </Box>
          )}
        </Box>
        <Divider />
        <Box
          className={classes.content}
          overflow={isNotSmallScreen ? 'auto' : undefined}
          zIndex={3}
        >
          <Typography variant="h3" className={classes.headline}>
            {t('overview.waitingForYou')}
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" width="100%">
              {user.isEmployee() && (
                <>
                  {settlementRequests.map((request) => {
                    if (request.status === 'DENIED') {
                      const {
                        text,
                        title,
                        link,
                        denied,
                        type
                      } = parseSettlementRequest(request)
                      const isExpiredAnnual = ['ANNUAL', 'ANNUAL_POZP', 'ANNUAL_CHANGE'].includes(request.type) && !user.data.allowAnnual
                      return (
                        <OverviewItem
                          gutterBottom
                          key={request.id}
                          title={title}
                          link={link}
                          denied={denied.length > 0}
                          disabled={isExpiredAnnual}
                        >
                          {isExpiredAnnual && <span style={{display: 'flex', alignItems: 'center', padding: '1rem 0'}}><Trans i18nKey={'overview.expiredAnnualNote'}>Již nelze dokončit.</Trans></span>}

                          {!isExpiredAnnual && text}
                          {denied.length > 0 && (
                            <List className={classes.deniedList}>
                              {denied.map((d, i) => renderDeniedItem(request, d as TAnnualRebateName & TMonthlyRebateName, i))}
                            </List>
                          )}
                        </OverviewItem>
                      )
                    }
                    return null
                  })}
                  <OverviewItem
                    link={`/${user.data.id}/rebates`}
                    title={t('common.guide')}
                    gutterBottom
                  >
                    <List>
                      {!user.blockedAnnualOverride() && (
                        <ListItem>
                          <ListItemText>
                            {t('overview.guideMonthly', {
                              year: getYear('MONTHLY'),
                            })}
                          </ListItemText>
                        </ListItem>
                      )}
                      {user.data.allowAnnual && (
                        <ListItem>
                          <ListItemText>
                            {t('overview.guideAnnual', {
                              year: getYear('ANNUAL'),
                            })}
                          </ListItemText>
                        </ListItem>
                      )}
                    </List>
                  </OverviewItem>
                  {settlementRequests.map((request) => {
                    if (['APPLY', 'CONFIRMED'].includes(request.status)) {
                      const {
                        text,
                        title,
                        link,
                        denied,
                      } = parseSettlementRequest(request)
                      return (
                        <OverviewItem
                          gutterBottom
                          key={request.id}
                          title={title}
                          link={link}
                          denied={denied.length > 0}
                        >
                          {text}
                          {denied.length > 0 && (
                            <List className={classes.deniedList}>
                              {denied.map((d, i) => renderDeniedItem(request, d as TAnnualRebateName & TMonthlyRebateName, i))}
                            </List>
                          )}
                        </OverviewItem>
                      )
                    }
                    return <></>
                  })}

                  {user.allowMessages() ? (
                    <>
                      {messagesData &&
                      messagesData.messages &&
                      messagesData.messages.items.length > 0 ? (
                        messagesData.messages.items.map((message) => (
                          <OverviewItem
                            link={`/${user.data.id}/messages`}
                            title={t('message.new')}
                            key={message.id}
                            gutterBottom
                          >
                            {t('overview.newMessage', {
                              context: message.from.gender,
                              role: user.isEmployee()
                                ? //t('role.accountant')
                                  t(`role.${message.from.role.toLowerCase()}`)
                                : t('role.user'),
                              name: message.from.fullname,
                              subject: message.subject,
                              sentAt: moment(message.createdAt).format(
                                'D.M.YYYY',
                              ),
                            })}
                          </OverviewItem>
                        ))
                      ) : (
                        <OverviewItem
                          link={`/${user.data.id}/messages`}
                          title={t('common.messages')}
                          gutterBottom
                        >
                          „{t('overview.noMessages')}…“
                        </OverviewItem>
                      )}
                    </>
                  ) : null}

                  {documentsData &&
                    documentsData.documents &&
                    documentsData.documents.items.map((document) => (
                      <OverviewItem
                        link={`/${user.data.id}/documents`}
                        title={t('documents.uploaded')}
                        key={document.id}
                        gutterBottom
                      >
                        {t('overview.newDocument', {
                          context: document.files[0].createdBy.gender,
                          role: translateRole(document.files[0].createdBy.role),
                          name: document.files[0].createdBy.fullname,
                          document: document.files[0].filename,
                        })}
                      </OverviewItem>
                    ))}
                </>
              )}

              {!user.isEmployee() && (
                <>
                  {user.allowMessages() && (
                    <OverviewItem
                      link={`/${user.data.id}/messages`}
                      title={t('message.new_plural')}
                      gutterBottom
                    >
                      {messagesCount === 0
                        ? `„${t('overview.noMessages')}…“`
                        : t('overview.newMessages', { count: messagesCount })}
                    </OverviewItem>
                  )}

                  <OverviewItem
                    link={`/${user.data.id}/settlement-requests`}
                    title={t('settlementRequest.new_plural')}
                    gutterBottom
                  >
                    {requestsCount === 0
                      ? `„${t('overview.noRequests')}…“`
                      : t('overview.newRequests', {
                          count: requestsCount,
                        })}
                  </OverviewItem>

                  {documentsData &&
                    documentsData.documents &&
                    documentsData.documents.items
                    .filter(document => document.files[0].createdBy.id !== user.data.id)
                    .map(document => (
                      <OverviewItem
                        link={`/${user.data.id}/documents`}
                        title={t('documents.uploaded')}
                        key={document.id}
                        gutterBottom
                      >
                        {t('overview.newDocument', {
                          context: document.files[0].createdBy.gender,
                          role: translateRole(document.files[0].createdBy.role),
                          name: document.files[0].createdBy.fullname,
                          document: document.name,
                        })}
                      </OverviewItem>
                    ))}
                </>
              )}
            </Box>

            {showNotice && (
              <OverviewNotice>
                <List>
                  {user.isEmployee() &&
                    user.data.customer.annualRebatesPeriodEndNotice && (
                      <ListItem>
                        <ListItemText>
                          <Trans i18nKey="overview.annualRebatesPeriodEnding">
                            Období ročního zúčtování se pomalu blíží ke konci.
                            Chcete-li ještě uplatnit nějaké roční slevy nebo
                            provést změny, tak máte čas do
                            {{
                              annualRebatesPeriodEnd: moment(
                                user.data.customer.annualRebatesPeriodEnd,
                              ).format('D.M'),
                            }}
                            .
                          </Trans>
                        </ListItemText>
                      </ListItem>
                    )}
                  {user.isEmployee() &&
                    user.data.customer.annualRebatesPeriodStartNotice && (
                      <ListItem>
                        <ListItemText>
                          <Trans i18nKey="overview.annualRebatesPeriodIncoming">
                            Bliží se období ročního zúčtování na dani. Chcete-li
                            uplatnit roční slevy, tak doporučujeme si obstarat
                            patřiná potvrzení.
                          </Trans>
                        </ListItemText>
                      </ListItem>
                    )}
                  {showNoticeMonthly && (
                    <ListItem>
                      <ListItemText>
                        {t('overview.monthlyReminder')}
                      </ListItemText>
                    </ListItem>
                  )}
                  {showNoticeForeigner && (
                    <ListItem>
                      <ListItemText>
                        {t('overview.missingForeignerFiles', {
                          context: user.data.gender,
                        })}
                      </ListItemText>
                    </ListItem>
                  )}
                  {/* <ListItem>
                  <ListItemText>
                    Platnost potvrzení Sleva na studenta se bude končit 20.2.
                    Chcete-li pokračovat v uplatňování slevy, tak bude nutné si
                    obstarat
                  </ListItemText>
                </ListItem> */}
                </List>
              </OverviewNotice>
            )}
          </Box>
        </Box>
        <FooterIlustration showNotice={showNotice} />
      </Box>
    </InsideLayout>
  )
}

export default Overview
