import {Bold} from '../../../../../components/CommonBox'
import {NexusGenFieldTypes} from 'kubik-server'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {useTheme} from '@material-ui/core/styles'

const PensionInsuranceRebateOverview: React.FC<{
  data: NexusGenFieldTypes['PensionInsuranceRebate']
}> = ({ data }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  if (data.type === 'REMOVE') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={compactScreen ? 12 : 5}>
        <Bold>{t('annualPensionInsuranceRebate.feeAmountLabel')}</Bold>
      </Grid>
      <Grid item xs={compactScreen ? 12 : 7}>
        <Typography>{data.feeAmount} Kč</Typography>
      </Grid>
      <Grid item xs={12}>
        <Bold mb={1} display="block">
          {t('common.uploadedConfirmations')}
        </Bold>
      </Grid>
      <Box pl={compactScreen ? 0 : 4}>
        {data.feeConfirmationFiles &&
          data.feeConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
        {data.contractConfirmationFiles &&
          data.contractConfirmationFiles.map((file) => (
            <FileLink key={file.id} file={file} />
          ))}
      </Box>
    </Grid>
  )
}

export default PensionInsuranceRebateOverview
