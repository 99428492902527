import {
  PomocGrey1,
  RegistraceZamestnance as DanoveRezidenstvi,
  Shrnuti,
  UdajeSpolecnosti as Dotaznik,
} from '../../../../../components/icons/KubikIcons'
import {styled} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import React, {useEffect, useMemo, useRef} from 'react'
import useUser from '../../../../../hooks/useUser'
import PercentageStepper, {PercentageStep} from "../../../../../components/PercentageStepper";
import {IOwnMonthlyRebateData, OWN_MONTHLY_REBATE_OVERVIEW} from '../../TaxRebates/gql'
import {useQuery} from 'react-apollo'
import {get} from 'lodash'

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
})

interface IProps {
  activeStep: 1 | 2 | 3 | 4 | 5
  noApplyingRebates?: boolean
  linear?: boolean
  width?: number | string
}

const Stepper: React.FC<IProps> = (props) => {
  const stepperRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { user } = useUser()
  const { activeStep, noApplyingRebates = false } = props

  let muiActiveStep = activeStep - 1
  if (!user.shouldHaveResidenceStep() && activeStep >= 3) {
    muiActiveStep = muiActiveStep - 1
  }

  const { data } = useQuery<IOwnMonthlyRebateData>(
    OWN_MONTHLY_REBATE_OVERVIEW,
    {
      fetchPolicy: 'cache-and-network',
      skip: !user || !user.data.monthlyRebate || !user.data.monthlyRebate.settlementRequest
    },
  )

  useEffect(() => {
    const active = activeRef.current
    const stepper = stepperRef.current
    const container = stepper && stepper.parentElement

    if (active && stepper && container) {
      if (container.scrollWidth > container.clientWidth) {
        const containerCenter = container.clientWidth / 2
        const activeCenter = active.offsetLeft + active.clientWidth / 2
        const diff = activeCenter - containerCenter

        container.scrollTo(diff, 0)
      }
    }
  }, [stepperRef, activeRef])

  const statuses = useMemo(() => {
    if (!data || !data.user.monthlyRebate.overview) {
      return null
    }

    const overview = data.user.monthlyRebate.overview
    const rebates = [
      get(overview.basicRebate, "status"),
      get(overview.childrenRebate, "status"),
      get(overview.disabilityRebate, "status"),
      get(overview.ztppRebate, "status")
    ].reduce((previous, current) => {
      if (previous === 'DENIED') {
        return "DENIED"
      }

      if (current === 'DENIED') {
        return "DENIED"
      }

      return "CONFIRMED"
    }, "CONFIRMED")

    return {
      questionnaire: get(overview.questionnaire, 'status'),
      rebates,
      taxResidence: user.getLastResidenceFilesStatus()
    }
  }, [data])

  const steps = useMemo<PercentageStep[]>(() => {
    let steps: PercentageStep[] = [
      {
        label: t('monthlyRebateWizard.stepper.questionnaire'),
        icon: <Dotaznik fontSize="default" />,
        state: get(statuses, 'questionnaire'),
        link: `/${user.data.id}/monthly-rebates/questionnaire`
      },
    ]

    if (!noApplyingRebates) {
      if (user.shouldHaveResidenceStep()) {
        steps.push({
          label: t('monthlyRebateWizard.stepper.taxResidence'),
          icon: <DanoveRezidenstvi fontSize="default" />,
          state: get(statuses, 'taxResidence'),
          link: `/${user.data.id}/monthly-rebates/taxResidence`
        })
      }

      steps.push({
        label: t('monthlyRebateWizard.step1Heading'),
        icon: <PomocGrey1 fontSize="default" />,
        state: get(statuses, 'rebates'),
        link: `/${user.data.id}/monthly-rebates/root`
      })
    }

    steps.push({
      label: t('monthlyRebateWizard.step2Heading'),
      icon: <Shrnuti fontSize="default" />
    })

    return steps
  }, [user, t, statuses, noApplyingRebates])

  return (
    <Container>
      <PercentageStepper
        steps={steps}
        activeStep={muiActiveStep}
        ref={stepperRef}
        nonLinear={!!statuses}
      />
    </Container>
  )
}

export default Stepper
