import React, { useMemo } from "react"
import { Trans, useTranslation } from "react-i18next"
import moment, { MomentInput } from "moment"
import { NexusGenRootTypes } from "kubik-server"

import { List } from "./List"

import InfoBox from "../../../../../components/InfoBox"
import { Bold } from "../../../../../components/CommonBox"
import TooltipText from "../../../../../components/TooltipText"
import { months, monthsMap } from "../../../../../utils"
import MonthsRange from "../../../../../shared/MonthsRange"

interface Rebates {
  from: MomentInput
  to: MomentInput
  type?: string
  closePeriodAt?: string
}

interface AppliedRebatesProps {
  rebates: Rebates[]
  /**
   * @default "MONTHLY"
   */
  type?: "MONTHLY" | "ANNUAL" | "PREV_YEAR"
}

export const AppliedRebates: React.FC<AppliedRebatesProps> = ({ rebates, type = "MONTHLY" }) => {
    const { t } = useTranslation()

    const periods = useMemo(() => {
        return rebates.map((rebate, i) => {
          if (rebate.type === "CLOSE_PERIOD") {
            return ""
          }

          const before = i - 1;
          const nextClosedPeriod = before > -1 && rebates[before].type === "CLOSE_PERIOD" ? rebates[before] : null

          const fromMoment = moment(rebate.from)
          const toMoment = moment(nextClosedPeriod ? nextClosedPeriod.closePeriodAt : rebate.to)

          const sameYear = fromMoment.isSame(toMoment, "year")

          // @ts-ignore
          const from = monthsMap[fromMoment.format("MMMM")] || fromMoment.format("MMMM")
          // @ts-ignore
          const to = monthsMap[toMoment.format("MMMM")] || toMoment.format("MMMM")

          return (
            <>
              {t("common.from").toLowerCase()} <Bold>{from}{sameYear ? " " : ` ${fromMoment.year()} `}</Bold>{t("common.to").toLowerCase()} <Bold>{to} {toMoment.format("YYYY")}</Bold>
            </>
          )
        })
        .filter(rebate => !!rebate)
        .reverse()
    }, [rebates, moment])

    return (
        <InfoBox title={t('rebate.view.title', { context: type })} maxWidth={480} mt={4}>
            <List>
                {periods.map((period, i) => <li key={i}>{period}</li>)}
            </List>
            <Trans
                i18nKey='rebate.view.cta'
                tOptions={{
                  context: type,
                  buttonLabel: t("rebateChanges.wantToMakeChange")
                }}
                components={[<Bold key="0" />]}
            />
        </InfoBox>
    )
}

type TChild = NexusGenRootTypes['Child']

interface AppliedChildrenRebatesProps {
  rebates: Record<string, TChild[]>
  /**
   * @default "MONTHLY"
   */
  type?: "MONTHLY" | "ANNUAL" | "PREV_YEAR"
}

export const AppliedChildrenRebates: React.FC<AppliedChildrenRebatesProps> = ({ rebates, type = "MONTHLY" }) => {
  const { t } = useTranslation()

  return (
    <InfoBox title={t('rebate.view.title', { context: type })} maxWidth={480} mt={4}>
        <List>
          {Object.values(rebates).map(([child]) => {
            const fullname = `${child.firstname} ${child.lastname}`

            let applyInMonths = child.applyInMonths

            const closePeriodAt = child.closePeriodAt ? moment(child.closePeriodAt) : null

            if (closePeriodAt && closePeriodAt.isValid()) {
              applyInMonths = child.applyInMonths.filter(month => months.indexOf(month) <= closePeriodAt.month())
            }

             return (
               <li key={child.tag}>
                  <TooltipText tooltip={`${t("common.birthdate")}: ${moment(child.birthdate).format("D. M. YYYY")}`}>
                    {fullname}
                  </TooltipText>
                 {" - "}
                 <Bold>
                    <MonthsRange value={applyInMonths} />
                 </Bold>
               </li>
             )
          })}
        </List>
        <Trans
            i18nKey='rebate.childrenConfirmedTo'
            tOptions={{
                buttonLabel: t("rebateChanges.wantToMakeChange")
            }}
            components={[<Bold key="0" />]}
        />
      </InfoBox>
  )
}
