import { useTheme, styled } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FavoriteIcon from '@material-ui/icons/Favorite'
import Grid from '@material-ui/core/Grid'
import isString from 'lodash/isString'
import Language from '../../Language'
import MuiLinearProgress from '@material-ui/core/LinearProgress'
import logoPng from '../../../assets/logo.png'
import React from 'react'
import Navigation from '../../Navigation'
import MuiToolbar from '@material-ui/core/Toolbar'
import useAppState from '../../../hooks/useAppState'
import useGlobalState from '../../../hooks/useGlobalState'
import User from './User'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useScrollWindowToCenter from '../../../hooks/useScrollWindowToCenter'
import Drawer from './Drawer'
import {Link} from "react-router-dom";

const Header = styled('header')(({ theme }) => ({
  flexBasis: '100%',
  maxWidth: '100%',
  flexGrow: 0,
  height: theme.spacing(8),
  padding: theme.spacing(1, 0),
  backgroundColor: theme.palette.grey['100'],
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: theme.palette.grey['600'],
  position: 'relative',
  boxShadow: theme.shadows[4],
  zIndex: theme.zIndex.appBar,
}))
const LogoContainer = styled(Link)(({ theme }) => ({
  width: theme.shape.sidebar.width,
  marginRight: theme.spacing(4),
  cursor: "pointer"
}))
const LinearProgress = styled(MuiLinearProgress)({
  position: 'absolute',
  bottom: -3,
  left: 0,
  right: 0,
})
const Toolbar = styled(MuiToolbar)({
  minHeight: 36,
})
const Divider = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1 / 4),
  marginRight: theme.spacing(1),
  width: 1,
  backgroundColor: '#CCCCCC',
}))

interface Props {
  navigation?: boolean
  loading?: boolean
  outside?: boolean
  compactScreen?: boolean
  moveLoversLeft?: number
}

const Appbar: React.FC<Props> = (props) => {
  useScrollWindowToCenter(props.navigation)
  const { t } = useTranslation()
  const navigation = props.navigation ? <Navigation /> : undefined
  const { moveLoversLeft = 0 } = props

  const theme = useTheme()
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)

  const {
    state: { lovers },
  } = useGlobalState()

  const {
    state: { loading },
  } = useAppState()

  const logo = <img src={logoPng} width={200} height={40} alt="Logo" />
  const displayUser = !props.outside

  return (
    <Header>
      {lgScreen ? (
        <Toolbar>
          {navigation && <LogoContainer to={"/"}>{logo}</LogoContainer>}

          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <LogoContainer to={"/"}>{!navigation && logo}</LogoContainer>
            </Grid>
            <Grid item>
              {lovers && (
                <Box
                  fontFamily="fontFamily"
                  fontWeight="bold"
                  color="text.primary"
                  display="flex"
                  alignItems="center"
                  ml={`${moveLoversLeft}px`}
                  style={{ gap: "8px" }}
                >
                  {Array.isArray(lovers) && lovers.join(' & ')}
                  {isString(lovers) && lovers}
                  <FavoriteIcon color="error" fontSize="small" />
                  {t('header.lovesYou')}
                </Box>
              )}
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              {displayUser && <User />}
              <Divider />
              <Language />
            </Grid>
          </Grid>
        </Toolbar>
      ) : (
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <LogoContainer to={"/"}>{logo}</LogoContainer>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <Drawer navigation={navigation} displayUser={displayUser} />
            </Grid>
          </Grid>
        </Toolbar>
      )}
      {(loading || props.loading) && <LinearProgress />}
    </Header>
  )
}

export default Appbar
