import {
  IOwnAnnualLoanRebateData,
  IResetOwnAnnualLoanData,
  IUpdateOwnAnnualLoanRebateData,
  OWN_ANNUAL_LOAN_REBATE,
  RESET_OWN_ANNUAL_LOAN_REBATE,
  TLoanRebate,
  TUpdateOwnAnnualLoanRebateVariables,
  UPDATE_OWN_ANNUAL_LOAN_REBATE,
} from './gql'
import {Bold, Center, Divider, Green,} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../components/ChangeRebateButton'
import {getRebatesWithExtra} from '../../../../../../fragments/utils'
import {Observer} from 'mobx-react'
import {pickId} from '../../../../../../utils'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import Addresses from './Addresses'
import AppState from '../../../../../../components/AppState'
import DatePicker from '../../../../../../components/form/DatePicker'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Collapse from '@material-ui/core/Collapse'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Hypoteka from '../../../../../../components/icons/Hypoteka'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import LoanRebateOverview from '../../components/LoanRebateOverview'
import MonthPicker from '../../../../../../components/form/MonthPicker'
import NumberField from '../../../../../../components/form/NumberField'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Switch from '../../../../../../components/form/Switch'
import TextField from '../../../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import useForm, {Form} from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import moment from 'moment'
import {LoanRebateConditions} from "./LoanRebateConditions"
import HelpAdornment from '../../../../../../components/form/HelpAdornment'
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'
import { computed } from 'mobx'

// interface ISectionsProps {
//   formData: TLoanRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualLoanRebateData,
//     TUpdateOwnAnnualLoanRebateVariables
//   >
// }

const innerLayoutWidth = 680

const LoanRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualLoanRebateData,
    TUpdateOwnAnnualLoanRebateVariables
  >(UPDATE_OWN_ANNUAL_LOAN_REBATE, { onError() {} })

  const { data, loading, error } = useQuery<IOwnAnnualLoanRebateData>(
    OWN_ANNUAL_LOAN_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualLoanData>(RESET_OWN_ANNUAL_LOAN_REBATE, {
    onError() {},
  })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const formData = get(
    data,
    'user.annualRebate.loanRebate',
  ) as TLoanRebate | null

  const { bind, form } = useForm<TUpdateOwnAnnualLoanRebateVariables['data']>(
    formData,
    {
      purchaseDate: {
        label: t('annualLoanRebate.purchaseDateLabel'),
        help: t('annualLoanRebate.purchaseDateHelp', { context: "2023" }),
        rule: (data) => (data.type !== 'REMOVE' ? 'required|date' : ''),
      },
      interestAmount: {
        label: t('annualLoanRebate.interestAmountLabel'),
        help: t('annualLoanRebate.interestAmountHelp'),
        placeholder: t('common.amountPlaceholder', { amount: 300000 }),
        rule: (data, form) => {
          if (data.type === 'REMOVE') {
            return ''
          }
          const purchaseDate = form.getValue('purchaseDate')

          /**
           * V ročním zúčtování na rok 2021 platí podmínka, že pro obstarané bytové potřeby od 1. 1. 2021
           * platí maximální odpočet 150 000 korun (místo 300 000 Kč) odpočtu úroků úvěru na bydlení.
           */
          const isBefore2021 =
            purchaseDate &&
            moment(purchaseDate).isBefore(moment([2021]).startOf('year'))

          const months = form.getValue('applyInMonths')
          if (Array.isArray(months) && months.length > 0 && purchaseDate) {
            if (isBefore2021) {
              return `required|date|max:${(300000 / 12) * months.length}`
            } else {
              return `required|date|max:${(150000 / 12) * months.length}`
            }
          }
          return 'required|date'
        },
      },
      addresses: {
        type: 'list',
        label: t('annualLoanRebate.addressesLabel'),
        help: t('annualLoanRebate.addressesHelp'),
        placeholder: t('annualLoanRebate.addressesLabel') + '...',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      applyInMonths: {
        type: 'list',
        label: t('annualLoanRebate.applyInMonthsLabel'),
        help: t('annualLoanRebate.applyInMonthsHelp'),
        placeholder: t('annualLoanRebate.applyInMonthsLabel') + '...',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      otherPersonApplying: {
        label: t('annualLoanRebate.otherPersonApplying'),
        help: t('annualLoanRebate.otherPersonApplyingHelp'),
      },
      otherPersonFullname: {
        label: t('annualLoanRebate.otherPersonFullname'),
        placeholder:
          t('annualLoanRebate.otherPersonFullname', {
            context: 'placeholder',
          }) + '...',
        rule: (data) =>
          data.otherPersonApplying === true && data.type !== 'REMOVE'
            ? 'required'
            : '',
        omitOnSubmit: (data) => data.otherPersonApplying !== true,
      },
      otherPersonNationalIDNumber: {
        label: t('annualLoanRebate.otherPersonNationalIDNumber', {
          context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
        }),
        placeholder:
          t('annualLoanRebate.otherPersonNationalIDNumber', {
            context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
          }) + '...',
        help:
          user.data.nationality === 'CZ'
            ? t('annualLoanRebate.otherPersonNationalIDNumberHelp', { defaultValue: t("common.nationalIDNumberHelp", { context: "FOREIGNER" }) })
            : undefined,
        rule: (data) =>
          data.otherPersonApplying === true
            ? [
                ...(data.type !== 'REMOVE' ? ['required'] : []),
                user.data.nationality === 'CZ'
                  ? 'regex:/^([0-9]{9,10})$/'
                  : 'regex:/^(([0-9]{9,10})|(((0)[1-9]|[1-2][0-9]|(3)[0-1]).(((0)[1-9])|((1)[0-2])).((20)|(19)){1}[0-9]{2})){1}$/',
              ]
            : undefined,
        omitOnSubmit: (data) => data.otherPersonApplying !== true,
      },
      otherAddress: {
        // TBD sjednotit na otherPersonAddress?
        label: t('annualLoanRebate.otherAddress'),
        placeholder:
          t('annualLoanRebate.otherAddress', {
            context: 'placeholder',
          }) + '...',
        rule: (data) =>
          data.otherPersonApplying === true && data.type !== 'REMOVE'
            ? 'required'
            : '',
        omitOnSubmit: (data) => data.otherPersonApplying !== true,
      },

      interestAmountConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualLoanRebate.interestAmountConfirmationLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
      loanContractFiles: {
        type: 'multi_relation',
        label: t('annualLoanRebate.loanContractLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
      propertyConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualLoanRebate.propertyConfirmationLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
      onFieldChange(fieldPath, _, form) {
        if (fieldPath === 'purchaseDate') {
          form.setField('interestAmount', null)
          form.setField('applyInMonths', [])
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'loanRebate')

  const cleanRebate = async () => {
    if (formData) {
      const interestAmountConfirmationFiles =
        formData.interestAmountConfirmationFiles || []
      const loanContractFiles = formData.loanContractFiles || []
      const propertyConfirmationFiles = formData.propertyConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            purchaseDate: null,
            interestAmount: null,
            applyInMonths: { set: [] },
            addresses: { set: [] },
            otherPersonApplying: false,
            otherPersonFullname: null,
            otherPersonNationalIDNumber: null,
            otherAddress: null,
            interestAmountConfirmationFiles: {
              delete: interestAmountConfirmationFiles.map(pickId),
            },
            loanContractFiles: { delete: loanContractFiles.map(pickId) },
            propertyConfirmationFiles: {
              delete: propertyConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.loanRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'LOAN' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')
    const status = form.getValue('status')

    return (
      isFirstRequest ||
      isPrevRemoved ||
      isPrevDenied ||
      status === 'DENIED' ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD' ||
      isPrevCancelled
    )
  })

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />
      <ScrollToTop />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualLoanRebate.sideHint'}>
                <strong>Slevu na hypotéku</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). Uplatňování slevy Vám může <strong>každoročně ušetřit až 45 000 Kč</strong>. Záleží na <strong>výši zaplacené daně a úroků</strong>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={t('annualLoanRebate.heading')}
            subHeading={
              <Trans i18nKey="annualLoanRebate.subHeading">
                Chcete-li uplatňovat Slevu na hypotéku, tak je nutné <Green>vyplňte údaje k hypotéce</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
            icon={<Hypoteka fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelLoan')}
                    </CancelRebateSection>
                  )
                }

                if (showForm.get()) {
                  return <FormSection bind={bind} form={form} />
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <LoanRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    submitToBack={submitToBack}
                    backTo={`/${user.data.id}/annual-rebates/root`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default LoanRebate

const FormSection: React.FC<{
  bind: (fieldPath: string) => any
  form: Form
}> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { bind, form } = props

  return (
    <>
      <Box
        maxWidth="100%"
        width={innerLayoutWidth}
      >
        <LoanRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Observer>
        {() => {
          const from = form.getValue('purchaseDate')

          const previousMonth = moment(from).isValid() ? moment(from).subtract(1, 'month').startOf('month') : null

          return (
            <>
              <Box maxWidth="100%" width={innerLayoutWidth} mb={4}>
                <DatePicker
                  inlineLabel={t('annualLoanRebate.purchaseDateLabel')}
                  maxDate={moment()
                    .endOf('month')
                    .toISOString()}
                  autoOk
                  {...bind('purchaseDate')}
                />
              </Box>

              <Box maxWidth="100%" width={innerLayoutWidth} mb={4}>
                <NumberField
                  inlineLabel={t('annualLoanRebate.interestAmountDescription')}
                  {...bind('interestAmount')}
                />
              </Box>

              <Box maxWidth="100%" width={innerLayoutWidth} mb={4}>
                <MonthPicker
                  inlineLabel={t('annualLoanRebate.applyInMonthsDescription', { context: "2023" })}
                  disabledBefore={previousMonth}
                  year={user.data.customer.yearOfAnnualRebates}
                  {...bind('applyInMonths')}
                />
              </Box>
            </>
          )
        }}
      </Observer>

      <Box maxWidth="100%" width={680} mb={4}>
        <Observer>{() => <Addresses {...bind('addresses')} />}</Observer>
      </Box>

      <Observer>
        {() => (
          <>
            <Box maxWidth="100%" width={innerLayoutWidth}>
              <Switch {...bind('otherPersonApplying')} />
            </Box>

            <Box maxWidth="100%" width={innerLayoutWidth} pl={2}>
              <Collapse in={form.getValue('otherPersonApplying') === true}>
                <Divider maxWidth="100%" width={100} my={4} />

                <Box mb={4}>
                  <Typography align='center'>
                    <Trans i18nKey='annualLoanRebate.text3'>
                      Vyplňte údaje o <Green>další osobě</Green>, která s Vámi uplatňuje slevu:
                    </Trans>
                  </Typography>
                </Box>

                <Box mb={4}>
                  <TextField inline {...bind('otherPersonFullname')} />
                </Box>

                <Box mb={4}>
                  <TextField inline {...bind('otherPersonNationalIDNumber')} />
                </Box>

                <TextField inline {...bind('otherAddress')} />
              </Collapse>
            </Box>
          </>
        )}
      </Observer>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold display="flex" alignItems="flex" maxWidth="100%" width={innerLayoutWidth}>
        {t('annualLoanRebate.text2')}
        <HelpAdornment inline text={<Trans i18nKey="common.uploadHelp" />} />
      </Bold>

      <Divider maxWidth="100%" width={160} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <Uploader
              {...bind('interestAmountConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>

      <Box my={4}>
        <Observer>
          {() => (
            <Uploader
              {...bind('loanContractFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>

        <Observer>
          {() => (
            <Uploader
              {...bind('propertyConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>
    </>
  )
}
