import ChangeRebateButton, {ChangeRebateChoice,} from '../../components/ChangeRebateButton'
import {DateLabel, DatepickerWrapper, DateWrapper, InlineLabel,} from '../../../../../../shared/PrevPeriods/PrevPeriods'
import {Bold, Center, Divider, Green} from '../../../../../../components/CommonBox'
import {makeStyles, styled} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {TPrevEmployer} from './gql'
import {Trans, useTranslation} from 'react-i18next'
import {DeniedRebateNotice} from '../../../../../../components/layouts/RebateLayout'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CenterTriplet from '../../../../../../components/layouts/CenterTriplet'
import DatePicker from '../../../../../../components/form/DatePicker'
import ErrorButton from '../../../../../../components/ErrorButton'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import NumberField from '../../../../../../components/form/NumberField'
import React, {useMemo, useState} from 'react'
import TextField from '../../../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import useForm from '../../../../../../hooks/useForm'
import useUser from '../../../../../../hooks/useUser'
import CancelRebateSection from '../../components/CancelRebateSection'
import HelpAdornment from '../../../../../../components/form/HelpAdornment'
import {BlueButton} from '../../../../../../pages/Inside/pages/TaxRebates'

const Heading = styled(Typography)(({ theme }) => ({
  width: 550,
  maxWidth: '100%',
  margin: '0 auto',
  marginTop: "32px"
}))

const OutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: '28px !important',
  borderColor: '#4EBE4E',
  backgroundColor: 'transparent',
  fontWeight: 'bold',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  borderWidth: '2px !important',
  color: '#4EBE4E',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#4EBE4E',
  },
}))

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 700,
    margin: 'auto',
  },
  label: {
    flex: 1,
  },
  fieldContainer: {
    [theme.breakpoints.up('sm')]: {
      width: 195,
    },
  },
  datePicker: {
    width: 160,
  },
  datePickerLabel: {
    opacity: 0.7,
    paddingRight: theme.spacing(0.8),
    paddingLeft: theme.spacing(0.8),
  },
  dateLabel: {
    height: 49,
    alignSelf: 'baseline',
    '& p': {
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
  }
}))

interface IProps {
  onRelationUpdate: (id: string | number, data: any) => void
  onCreateNew?: () => void
  onDelete: () => void
  onResetPrevEmployer: (id: string) => void
  prevEmployer: TPrevEmployer
  num: number
}
const Form: React.FC<IProps> = (props) => {
  const { onRelationUpdate } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()

  const { id, ...employer } = props.prevEmployer

  const [confirmed, setConfirmed] = useState(employer.status === 'CONFIRMED')

  const { bind, form } = useForm<TPrevEmployer>(
    employer || {},
    {
      companyName: {
        rule: 'required',
        label: t('annualRebatePreviousEmployers.companyName'),
        placeholder: `${t(
          'annualRebatePreviousEmployers.companyName_placeholder',
        )}...`,
      },
      dateOfEmployment: {
        label: t('annualRebatePreviousEmployers.dateOfEmployment'),
        rule: (data, form) =>
          `required|previousEmployersDates:${JSON.stringify(
            form
              .getNeighbourForms()
              .filter((f) => !f.getValue('deleteFlag'))
              .map((f) => ({
                dateOfEmployment: f.getValue('dateOfEmployment'),
                dateOfQuit: f.getValue('dateOfQuit'),
              })),
          )}`,
      },
      dateOfQuit: {
        label: t('annualRebatePreviousEmployers.dateOfQuit'),
        rule: (data, form) =>
          `required|previousEmployersDates:${JSON.stringify(
            form
              .getNeighbourForms()
              .filter((f) => !f.getValue('deleteFlag'))
              .map((f) => ({
                dateOfEmployment: f.getValue('dateOfEmployment'),
                dateOfQuit: f.getValue('dateOfQuit'),
              })),
          )}`,
      },
      totalRevenue: {
        label: t('annualRebatePreviousEmployers.totalRevenue'),
        placeholder: t('annualRebatePreviousEmployers.row', { row: 1 }),
        rule: 'required',
      },
      // incomeInsuranceSum: {
      //   label: t('annualRebatePreviousEmployers.incomeInsuranceSum', {
      //     row: 6,
      //   }),
      //   placeholder: t('annualRebatePreviousEmployers.row', { row: 6 }),
      //   rule: 'required',
      // },
      // actualDeductedTax: {
      //   label: t('annualRebatePreviousEmployers.actualDeductedTax', { row: 9 }),
      //   placeholder: t('annualRebatePreviousEmployers.row', { row:  9}),
      //   rule: 'required',
      // },
      totalTaxAdvance: {
        label: t('annualRebatePreviousEmployers.totalTaxAdvance', { row: 8 }),
        placeholder: t('annualRebatePreviousEmployers.row', { row: 8 }),
        rule: 'required',
      },
      totalMonthlyTaxBonuses: {
        label: t('annualRebatePreviousEmployers.totalMonthlyTaxBonuses', {
          row: 9,
        }),
        placeholder: t('annualRebatePreviousEmployers.row', { row: 9 }),
        rule: 'required',
      },
      lifeInsuranceContribution: {
        label: t('annualRebatePreviousEmployers.lifeInsuranceContribution', {
          row: 10,
        }),
        placeholder: t('annualRebatePreviousEmployers.row', { row: 10 }),
        rule: 'numeric',
      },
      incomeConfirmationFiles: {
        label: t('annualRebatePreviousEmployers.incomeConfirmationFiles'),
        type: 'multi_relation',
        rule: 'required',
        isFileRelation: true
      },
    },
    {
      onSubmit(formData, form) {
        if (form.state.isDirty) {
          onRelationUpdate(id, formData)
        }
      },
      onFieldChange(fieldpath, value, form) {
        if (fieldpath === 'dateOfEmployment' || fieldpath === 'dateOfQuit') {
          if (form.rootForm) form.rootForm.isValid()
        }
      },
    },
  )

  const inCollision = (date: string | undefined) => {
    if (date) {
      const collisions = form.getNeighbourForms().map((otherDate) => {
        const dateOfEmployment = otherDate.getValue('dateOfEmployment')
        const dateOfQuit = otherDate.getValue('dateOfQuit')
        if (dateOfEmployment && dateOfQuit) {
          return moment(date).isBetween(
            dateOfEmployment,
            dateOfQuit,
            'day',
            '[]',
          )
        }
        return false
      })
      return collisions.includes(true)
    }
    return false
  }

  const removeButton = useMemo(() => {
    // The employer is new - has no history
    if (!employer.history || employer.history.length === 0) {
      return (
        <ErrorButton onClick={props.onDelete} fullWidth>
          {t('annualRebatePreviousEmployers.removePreviousEmployer')}
        </ErrorButton>
      )
    }

    // There are some saved changes - status  == APPLY, or user wants to change CONFIRMED employer
    if (
      employer.status === 'APPLY' ||
      (employer.status === 'CONFIRMED' && confirmed === false)
    ) {
      return (
        <ErrorButton onClick={() => props.onResetPrevEmployer(id)}>
          {t('annualRebatePreviousEmployers.removeApplication')}
        </ErrorButton>
      )
    }

    if (employer.status === 'DENIED') {
      return (
        <ErrorButton onClick={() => form.setField('deleteFlag', true)}>
          {t('annualRebatePreviousEmployers.removePreviousEmployer')}
        </ErrorButton>
      )
    }

    return null
  }, [employer, props.onDelete])

  return (
    <Observer>
      {() => {
        const deleteFlag = form.getValue('deleteFlag')
        const companyName = form.getValue('companyName')

        return (
          <Box>
            <Heading variant="h3" align="center">
              <Trans i18nKey="annualRebatePreviousEmployers.fillInEmployer">
                Vyplňte údaje o Vašem předchozím zaměstnání a údaje z
                <Green>Potvrzení o zdanitelných příjmech</Green>
              </Trans>
            </Heading>

            <Divider my={4} maxWidth={180} />

            <Box mb={4}>
              <Typography variant="h3" align="center">
                {props.num}. {t('rebate.previousEmployer')}
              </Typography>
            </Box>

            {employer.status === 'DENIED' && (
              <Box maxWidth="100%" width={728} my={2} mx="auto">
                <DeniedRebateNotice fullwidth variant="employer">
                  {employer.commentOfDenied}
                </DeniedRebateNotice>
              </Box>
            )}

            {deleteFlag ? (
              <>
                <Center maxWidth="100%" width={700} my={2} mx="auto">
                  <CancelRebateSection
                    onUndo={() => form.setField('deleteFlag', false)}
                  >
                    {t('annualRebatePreviousEmployers.cancelPrevEmployer', {
                      companyName,
                    })}
                  </CancelRebateSection>
                </Center>

                {props.onCreateNew && <Divider mb={2} />}

                <CenterTriplet
                  left={
                    props.onCreateNew && (
                      <OutlinedButton
                        onClick={props.onCreateNew}
                        variant="outlined"
                        color="primary"
                        size="large"
                      >
                        {t('annualRebatePreviousEmployers.addPreviousEmployer')}
                      </OutlinedButton>
                    )
                  }
                />
              </>
            ) : (
              <>
                {confirmed && (
                  <Center maxWidth="100%" width={700} my={2} mx="auto">
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        label={t(
                          'annualRebatePreviousEmployers.changePreviousEmployer',
                        )}
                        onClick={() => setConfirmed(false)}
                      />
                      <ChangeRebateChoice
                        label={t(
                          'annualRebatePreviousEmployers.removePreviousEmployer',
                        )}
                        onClick={() => {
                          form.setField('deleteFlag', true)
                        }}
                      />
                    </ChangeRebateButton>
                  </Center>
                )}

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    <Observer>
                      {() => (
                        <TextField
                          {...bind('companyName')}
                          inline
                          className={classes.fieldContainer}
                          disabled={confirmed}
                        />
                      )}
                    </Observer>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="flex-start"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Grid item xs>
                        <InlineLabel>
                          {t('annualRebatePreviousEmployers.workedHere', {
                            context: user.data.gender,
                            year: user.data.customer.yearOfAnnualRebates,
                          })}
                        </InlineLabel>
                      </Grid>
                      <Observer>
                        {() => (
                          <DateWrapper
                            item
                            sm={12}
                            md
                            container
                            alignItems="flex-start"
                            spacing={1}
                          >
                            <Grid item sm={12} md={6} container wrap="nowrap">
                              <DateLabel>{t('common.from')}</DateLabel>
                              <DatepickerWrapper>
                                <DatePicker
                                  {...bind('dateOfEmployment')}
                                  hideLabel
                                  fullWidth
                                  disableFuture={true}
                                  minDate={moment({
                                    year:
                                      user.data.customer.yearOfAnnualRebates,
                                    month: 0,
                                    date: 1,
                                  })}
                                  maxDates={[
                                    {
                                      date: form.getValue('dateOfQuit'),
                                      message: t('error.maxDateOfEmploment'),
                                    },
                                    {
                                      date: moment({
                                        year:
                                          user.data.customer
                                            .yearOfAnnualRebates,
                                        month: 11,
                                        date: 31,
                                      }),
                                      message: t('error.maxDateAnnualYearEnd'),
                                    },
                                  ]}
                                  shouldDisableDate={(day) =>
                                    Boolean(
                                      day && inCollision(day.toISOString()),
                                    )
                                  }
                                  autoOk
                                  disabled={confirmed}
                                />
                              </DatepickerWrapper>
                            </Grid>

                            <Grid item sm={12} md={6} container wrap="nowrap">
                              <DateLabel>{t('common.to')}</DateLabel>
                              <DatepickerWrapper>
                                <DatePicker
                                  {...bind('dateOfQuit')}
                                  hideLabel
                                  fullWidth
                                  disableFuture
                                  minDates={[
                                    {
                                      date: form.getValue('dateOfEmployment'),
                                      message: t('error.minDateOfEmploment'),
                                    },
                                    {
                                      date: moment({
                                        year:
                                          user.data.customer
                                            .yearOfAnnualRebates,
                                        month: 0,
                                        date: 1,
                                      }),
                                      message: t(
                                        'error.minDateAnnualYearStart',
                                      ),
                                    },
                                  ]}
                                  maxDate={moment({
                                    year:
                                      user.data.customer.yearOfAnnualRebates,
                                    month: 11,
                                    date: 31,
                                  })}
                                  shouldDisableDate={(day) =>
                                    Boolean(
                                      day && inCollision(day.toISOString()),
                                    )
                                  }
                                  autoOk
                                  disabled={confirmed}
                                />
                              </DatepickerWrapper>
                            </Grid>
                          </DateWrapper>
                        )}
                      </Observer>
                    </Grid>
                  </Grid>

                  <Divider my={4} />

                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
                      <Typography
                        variant="h3"
                        align="center"
                      >
                        <Trans i18nKey="annualRebatePreviousEmployers.fillIn">
                          Vyplňte údaje z řádku na
                          <a
                            href={
                              process.env.PUBLIC_URL + '/documents/vzor_pozp.pdf'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Potvrzení o zdanitelných příjmech
                          </a>
                        </Trans>
                      </Typography>
                      <HelpAdornment text={t('annualRebatePreviousEmployers.fillInHelp')} inline />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Observer>
                      {() => (
                        <NumberField
                          {...bind('totalRevenue')}
                          inline
                          showZero
                          className={classes.fieldContainer}
                          disabled={confirmed}
                        />
                      )}
                    </Observer>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Box display="flex" alignItems="baseline">
                      <Typography variant="h4" className={classes.label}>
                        {t('annualRebatePreviousEmployers.incomeInsuranceSum', {
                          row: 6,
                        })}
                      </Typography>
                      <Observer>
                        {() => (
                          <NumberField
                            {...bind('incomeInsuranceSum')}
                            hideLabel
                            showZero
                            className={classes.fieldContainer}
                            disabled={confirmed}
                          />
                        )}
                      </Observer>
                    </Box>
                  </Grid> */}
                  {/* <Grid item xs={12}>
        <Box display="flex" alignItems="baseline">
          <Typography variant="h4" className={classes.label}>
            {bind('actualDeductedTax').label}
          </Typography>
          <Observer>
            {() => (
              <NumberField
                {...bind('actualDeductedTax')}
                hideLabel
                className={classes.fieldContainer}
              />
            )}
          </Observer>
        </Box>
      </Grid> */}
                  <Grid item xs={12}>
                    <Observer>
                      {() => (
                        <NumberField
                          {...bind('totalTaxAdvance')}
                          inline
                          showZero
                          className={classes.fieldContainer}
                          disabled={confirmed}
                        />
                      )}
                    </Observer>
                  </Grid>
                  <Grid item xs={12}>
                    <Observer>
                      {() => (
                        <NumberField
                          {...bind('totalMonthlyTaxBonuses')}
                          inline
                          showZero
                          className={classes.fieldContainer}
                          disabled={confirmed}
                        />
                      )}
                    </Observer>
                  </Grid>
                  <Grid item xs={12}>
                    <Observer>
                      {() => (
                        <NumberField
                          {...bind('lifeInsuranceContribution')}
                          inline
                          showZero
                          className={classes.fieldContainer}
                          disabled={confirmed}
                        />
                      )}
                    </Observer>
                  </Grid>

                  <Divider my={4} />

                  <Grid item xs={12}>
                    <Box display="flex" justifyItems="center" alignItems="center">
                      <Bold>
                        {t('annualRebatePreviousEmployers.confirmationBelow')}
                      </Bold>
                      <HelpAdornment inline text={<Trans i18nKey="common.uploadHelp" />} />
                    </Box>

                    <Divider my={4} width="25%" />

                    <Observer>
                      {() => (
                        <Box maxWidth="100%" width={680} mb={4}>
                          <Bold display="flex" alignItems="center" mb={2}>
                            {bind('incomeConfirmationFiles').label}
                          </Bold>
                          <Uploader
                            {...bind('incomeConfirmationFiles')}
                            disabled={confirmed}
                          />
                        </Box>
                      )}
                    </Observer>
                  </Grid>
                </Grid>

                <Divider my={4} />

                <CenterTriplet
                  right={removeButton}
                  left={
                    props.onCreateNew && (
                      <BlueButton
                        onClick={props.onCreateNew}
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        {t('annualRebatePreviousEmployers.addPreviousEmployer')}
                      </BlueButton>
                    )
                  }
                />
              </>
            )}

            {!props.onCreateNew && removeButton && <Divider my={4} />}
          </Box>
        )
      }}
    </Observer>
  )
}

export default Form
