import React from "react"
import { Trans, useTranslation } from 'react-i18next'
import Box from "@material-ui/core/Box"
import CloudDownload from '@material-ui/icons/CloudDownload'

import useUser from "../../hooks/useUser"

import { RebateConditions, RebateExpandable } from '../../shared/Rebates/Conditions'
import { Divider, Green, Bold } from '../../components/CommonBox'

export const ChildrenRebateConditions: React.FC = () => {
    const { user } = useUser()
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
          <Divider maxWidth="100%" width={680} my={4} />

          <Green>{t("common.mainInfo")}</Green>

          <RebateConditions items={[
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail01">
              Slevu si může uplatnit každý zaměstnanec, který <Bold>vyživuje dítě či děti ve společné domácnosti</Bold>.
            </Trans>,
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail02">
              Slevu může uplatnit vždy <Bold>pouze jeden z rodičů či opatrovníků dítěte</Bold>.
            </Trans>,
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail03">
              Slevu je možné uplatňovat <Bold>do 18 let dítěte</Bold> (v případě pokračujícího studia až do dosáhnutí věku 26 let).
            </Trans>,
           <Trans i18nKey="monthlyChildrenRebate.conditionsDetail04">
              Sleva <Bold>na první dítě</Bold> je ve výši <Bold>1 267 Kč měsíčně</Bold> neboli <Bold>15 204 Kč ročně</Bold>.
            </Trans>,
           <Trans i18nKey="monthlyChildrenRebate.conditionsDetail05">
              Sleva <Bold>na druhé dítě</Bold> je ve výši <Bold>1 860 Kč měsíčně</Bold> neboli <Bold>22 320 Kč ročně</Bold>.
            </Trans>,
           <Trans i18nKey="monthlyChildrenRebate.conditionsDetail06">
              Sleva <Bold>na třetí a každé další dítě</Bold> je ve výši <Bold>2 320 Kč měsíčně</Bold> neboli <Bold>27 840 Kč ročně</Bold>.
            </Trans>,
           <Trans i18nKey="monthlyChildrenRebate.conditionsDetail07">
              Slevu můžete uplatňovat <Bold>po celou dobu co má dítě status studenta</Bold>, a to i v případě, že má dítě vlastní příjem.
            </Trans>,
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail08">
              Slevu můžete <Bold>uplatňovat i v průběhu letních prázdnin</Bold>, pokud je dítě student a pokračuje ve vzdělání.
            </Trans>,
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail09">
              Sleva je tradičně uplatňována <Bold>od měsíce, kdy se dítě narodí</Bold>.
            </Trans>,
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail10" tOptions={{ context: user.data.gender }}>
              V ročním zúčtování můžete <Bold>uplatnit slevu i za měsíce, kdy nejste zaměstnaný/á a dítě vyživujete</Bold>.
            </Trans>,
            <Trans i18nKey="monthlyChildrenRebate.conditionsDetail11">
              Slevu je možné uplatňovat <Bold>i přes letní prázdniny mimo poslední rok, ve kterém dítě končí školní docházku</Bold>.
            </Trans>,
          ]}
        />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
          {
            primaryText: t('monthlyChildrenRebate.docsInfo01'),
            supportText: t("monthlyChildrenRebate.docsInfo01b")
          },
          {
            primaryText: t("monthlyChildrenRebate.docsInfo02"),
            supportText: t("monthlyChildrenRebate.docsInfo02b")
          },
          {
            primaryText: t("monthlyChildrenRebate.docsInfo03"),
            supportText: t("monthlyChildrenRebate.docsInfo03b")
          }
        ]} />
        <Green>
          <Trans i18nKey={"common.documentsForDownload"}>
            Dokumenty ke stažení
          </Trans>
        </Green>
        <RebateConditions items={[
          <a href={process.env.PUBLIC_URL + '/documents/vzor_cestne_prohlaseni_deti.pdf'} download>
            <Box display="flex" alignItems="center">
              {t("monthlyChildrenRebate.otherParentAffidavit")}
              <CloudDownload style={{ marginLeft: "8px" }} />
            </Box>
          </a>
        ]} />
      </RebateExpandable>
    )
}
