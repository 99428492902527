import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Divider, Green} from '../../../../../../components/CommonBox'
import {RebateConditions, RebateExpandable} from '../../../../../../shared/Rebates/Conditions'
import {ExternalLink} from '../../../../../../pages/Inside/pages/MonthlyRebates/components/ExternalLink'

export const LoanRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualLoanRebate.conditionsDetail01'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který <strong>splácí hypotéku nebo úvěr ze stavebního spoření za účelem vlastního bydlení</strong> nebo bydlení svých blízkých.
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail02'}>
                Slevu si můžete uplatnit <strong>pouze na úroky</strong> (z půjčky) zaplacené v minulém roce.
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail03'}>
                Slevu si můžete uplatnit u <strong>půjčky na nákup bytu, domu, pozemku</strong> (do 4 let postavíte vlastní bydlení) nebo na <strong>rekonstrukci</strong> či <strong>stavbu domu</strong>.
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail04'}>
                Slevu si <strong>nemůžete uplatnit u půjčky na rekreační bydlení</strong> (chata nebo chalupa), <strong>investiční bydlení</strong> (pronájem nemovitosti) nebo <strong>pracovní prostor</strong> (kancelář nebo dílna).
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail05'}>
                Slevu si můžete uplatnit, pokud <strong>jste osobně účastníkem smlouvy</strong> (žadatel nebo spolužadatel ve smlouvě).
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail06'}>
                Máte-li <strong>více půjček</strong>, tak <strong>zaplacené úroky sečtěte dohromady</strong>.
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail07'}>
                <strong>Maximálně můžete uplatnit částku 300 000 Kč</strong> za celý rok splácení, pokud je <strong>nemovitost pořízená před 1. lednem 2021</strong>.
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail08'}>
                <strong>Maximálně můžete uplatnit částku 150 000 Kč</strong> za celý rok splácení, pokud je <strong>nemovitost pořízená od 1. ledna 2021</strong>.
            </Trans>,
            <Trans i18nKey={'annualLoanRebate.conditionsDetail10'} components={[<strong />]} />
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
            {
                primaryText: (
                    <Trans i18nKey="annualLoanRebate.docsInfo01">
                      Potvrzení o výši zaplacených úroků
                    </Trans>
                ),
                supportText: <Trans i18nKey="annualLoanRebate.docsInfo01b" />
            },
            {
                primaryText: (
                    <Trans i18nKey="annualLoanRebate.docsInfo02">
                        Kopie hypoteční/úvěrové smlouvy
                    </Trans>
                ),
                supportText: <Trans i18nKey="annualLoanRebate.docsInfo02b" />
            },
            {
                primaryText: (
                    <Trans i18nKey="annualLoanRebate.docsInfo03">
                      Výpis z katastru vlastníků nemovitostí
                    </Trans>
                ),
                supportText: <Trans i18nKey="annualLoanRebate.docsInfo03b" components={[<ExternalLink href="https://nahlizenidokn.cuzk.cz" />]} />
            }
        ]} />
      </RebateExpandable>
    )
}
